<template>
  <div v-if="patientVotDetail != null">
    <div v-for="(data, key) in patientVotDetail" :key="data">
      <span class="vot-data data-title"> {{ $t(key) }}: </span>
      <span class="vot-data data-value"> {{ data }} </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PatientVotDetails',
  computed: {
    ...mapState('ReviewVot', ['patientVotDetail'])
  }
}
</script>

<style lang="scss" scoped>

.vot-data {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.0125em;
}

.data-title {
  color: $placeholderText;
}

.data-value {
  color: var(--table-row-color);
}

</style>
