<template>
  <div>
    <v-card class="vot-card" elevation="10">
      <!-- <Breadcrumbs :items="breadcrumbs"></Breadcrumbs> -->

      <div>
        <h2 class="dark-text">
          {{ $t("home_fragment_vot") }}
          <hr class="ufmt-md ufmb-md " />

          <PatientVotDetails></PatientVotDetails>

          <div id="vot-nav">
            <button
              class="vot-nav-option move-right"
              v-on:click="this.setUnreviewedAndLoad"
              :class="list.reviewed === false ? 'active' : ''"
            ><div class="vot_tab_text">{{ $t("reviewVotListUnreviewed") }}</div></button>
            <div class="active filler"></div>
            <button
              class="vot-nav-option move-left"
              v-on:click="this.setReviewedAndLoad"
              :class="list.reviewed === true ? 'active' : ''"
            ><div class="vot_tab_text">{{ $t("reviewVotListReviewed") }}</div></button>
          </div>
        </h2>
      </div>

      <!-- Bootstrap grid layout -->
      <div>
        <div v-if="loading" class="loading-place-holder">
          <font-awesome-icon icon="spinner" class="fa-md" spin />
          {{ $t("reviewVotLoadingList") }}
        </div>

        <!-- Video list has loaded... -->
        <div v-else>
          <div v-if="movies != null && movies.length">
            <div class="vot-table-video-player-container">
              <div class="half-page">
                <Table
                  :searchable="false"
                  :columns="tableColumns"
                  :data="tableMovies"
                  :pageSize="itemsPerPage"
                  :title="videoType"
                  :colMaxWidth="120"
                ></Table>
              </div>
              <div class="half-page">
                <div v-if="current !== null && current.video_url">
                  <VideoViewer></VideoViewer>
                  <!-- Row for the video status form input -->
                  <div class="reviwVOTOptionTitle dark-text">{{$t('reviewVotFormStatus')}}</div>
                  <RadioGroup
                    class="flex-direction-column"
                    v-model="form.status"
                    :allOptions="statuses"
                    :multiLineLabel="true"
                    :label="''"
                  />
                  <br />
                  <Button
                    :label="$t('reviewVotFormSaveButton')"
                    v-on:click="this.submitForm"
                  />
                </div>

                <div v-else-if="current" class="video-player-skeleton dark-text">
                  <h5>{{ $t("reviewVotVideoUploading") }}</h5>
                </div>

                <div v-else class="video-player-skeleton">
                  <div class="vot_skele_text">
                    <div>{{$t("clickOnText")}}</div>
                    <i class="view-video-icon-skeleton"></i>
                    <div>{{$t("toSeeVideoText")}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div id="pageSelection" class="radio-toolbar">
              <PageTurner
                @changePageEvent="changePage"
                :currentPage="selectedPage"
                :pageSize="itemsPerPage"
                :totalItems="movies.length"
              ></PageTurner>
            </div>
          </div>

          <div v-else>
            <h5>
              <b>{{ $t("reviewVotNoVideos") }}</b>
            </h5>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import PageTurner from '../../../shared/components/PageTurner.vue'
import Button from '../../../shared/components/Button.vue'
import Table from '../../../shared/components/Table.vue'
import VideoViewer from './components/VideoViewer.vue'
import PatientVotDetails from './components/PatientVotDetails.vue'
import { mapState, mapActions } from 'vuex'
// import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import EventBus from '@/app/shared/event-bus.js'
import RadioGroup from '../../../shared/components/RadioGroup.vue'

export default {
  name: 'app',
  metaInfo: {
    title: 'Review Videos'
  },
  components: {
    PageTurner,
    VideoViewer,
    // Breadcrumbs,
    Table,
    Button,
    RadioGroup,
    PatientVotDetails
  },
  props: {
    patientId: {
      type: String,
      default: null
    }
  },
  data: (self) => ({
    // This form stores data for updating the video status, only has
    // the status field for now
    form: {
      status: ''
    },
    list: {
      reviewed: false
    },
    emptyFormSubmit: false,
    itemsPerPage: 10,
    selectedPage: 1,
    videoType: '',
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/',
        class: ''
      },
      {
        label: 'home_fragment_vot',
        link: '',
        class: 'active'
      }
    ]
  }),
  computed: {
    ...mapState('ReviewVot', [
      'current',
      'movies',
      'loading'
    ]),
    ...mapState(['userName', 'patientName']),
    tableMovies () {
      var start = (this.selectedPage - 1) * this.itemsPerPage
      var end = (this.selectedPage - 1) * this.itemsPerPage + this.itemsPerPage
      var viewMovies = [...this.movies].sort((m, n) =>
        m.videoDateTime > n.videoDateTime ? 1 : -1
      )
      viewMovies.reverse()
      viewMovies = viewMovies.slice(start, end)

      for (var movieNum in viewMovies) {
        var movie = viewMovies[movieNum]
        if (movie.status === 1) {
          movie.status_class = 'vot_status-confirm-icon'
        } else if (movie.status === 2) {
          movie.status_class = 'vot_status-miss-icon'
        } else {
          movie.status_class = ''
        }

        movie.View =
          '<div class="center" id="vot_icons" > <a class="no-decoration pointer" id=' +
          movie.id +
          'href="#">' +
          '<i class="view-video-icon"></i>' +
          '</a></div>'
        movie.clickable = true

        movie.status_col =
          '<div class="center" id="vot_icons" ><i class="' +
          movie.status_class +
          '"></i></div>'
      }
      return viewMovies
    },
    tableColumns () {
      var cols = [
        {
          name: 'reviewVotTablePatient',
          key: 'patientDetail',
          sortable: false,
          clickable: true
        },
        {
          name: 'reviewVotTableVideoDate',
          key: 'recorded_string',
          sortable: false
        },
        {
          name: 'reviewVotTableVideoUploadedDate',
          key: 'uploaded_string',
          sortable: false
        },
        {
          name: 'reviewVotTableVideo',
          key: 'View',
          sortable: false,
          clickable: true
        },
        {
          name: 'reviewVotTableStatus',
          key: 'status_col',
          sortable: false
        },
        {
          name: 'reviewVotTableDuration',
          key: 'duration',
          sortable: false
        }
      ]
      if (!window.location.toString().includes('reviewvot')) {
        cols = cols.filter((c) => c.key !== 'patientDetail')
      }
      if (!this.list.reviewed) {
        cols = cols.filter((c) => c.key !== 'status_col')
      }
      return cols
    },
    statuses () {
      return [
        {
          Id: 'cnf',
          Key: 'confirmed',
          Value: this.$t('confirmed'),
          LabelExtraInfo: this.$t('reviewVotFormTakenSubtext')
        },
        {
          Id: 'ncnf',
          Key: 'not_confirmed',
          Value: this.$t('not_confirmed'),
          LabelExtraInfo: this.$t('reviewVotFormMissedSubtext')
        }
      ]
    }
  },
  mounted () {
    if (window.location.toString().includes('reviewvot')) {
      this.loadPatientsAndVideosFromServer()
      this.videoType = this.$t('unreviewed_videos')
    } else {
      const patientId = this.patientId
      this.updatePatientIds(this.patientId.toString())
      var patientDict = {}
      patientDict[this.patientId] = this.patientName
      this.updatePatientDictionary(patientDict)
      this.loadPatientVotData(this.patientId.toString(), this.patientId, patientId, patientId.toString())
      this.loadVideosFromServer()
    }
    EventBus.$on('clickTableEvent', this.itemClicked)
  },
  methods: {
    changePage (page) {
      this.selectedPage = page
    },
    setReviewedAndLoad () {
      this.list.reviewed = true
      this.updateReviewed(true)
      this.loadVideosFromServer()
      this.selectedPage = 1
      this.videoType = this.$t('reviewed_videos')
    },
    setUnreviewedAndLoad () {
      this.list.reviewed = false
      this.updateReviewed(false)
      this.loadVideosFromServer()
      this.selectedPage = 1
      this.videoType = this.$t('unreviewed_videos')
    },
    itemClicked (column, item) {
      if (column === 'patientDetail') {
        window.location.href = '/dashboard/patient/' + item.unique_id
      } else if (column === 'View') {
        this.updateCurrentMovie(item)
      }
    },
    submitForm: function (e) {
      // This method is triggered when a user hits the 'Save Status'
      // button; it makes a PUT request to update the status and then a
      // GET request to get the new list of unreviewed videos.

      if (!this.form.status) {
        var that = this
        this.emptyFormSubmit = true
        setTimeout(function () {
          that.emptyFormSubmit = false
        }, 3000)
        return true
      }
      const statusMap = {
        confirmed: '1',
        not_confirmed: '2'
      }

      // make PUT request to IAM to update video status
      this.updateServerStatus(statusMap[this.form.status])

      // make PUT request to Video Server to update video status
      this.updateVideoStatus(statusMap[this.form.status])

      this.form.status = ''
      return true
    },
    ...mapActions('ReviewVot', [
      'updateServerStatus',
      'updateVideoStatus',
      'loadVideosFromServer',
      'updateReviewed',
      'updateCurrentMovie',
      'updatePatientDictionary',
      'updatePatientIds',
      'loadPatientsAndVideosFromServer',
      'loadPatientVotData'
    ])
  }
}
</script>

<style lang="scss" scoped>
.vot_skele_text{
  display: flex;
}
.reviwVOTOptionTitle{
  font-weight: 600;
  margin: 2px 0px;
}
.radio-toolbar div {
  display: flex;
  justify-content: center;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar span {
  float: left;
}

.radio-toolbar i {
  float: left;
  margin: 10px;
  align-self: center;
}

.radio-toolbar .page-label {
  font-size: 12px;
  padding: 5px;
}

.half-page {
  width: 50%;
  margin-top: 20px;
  padding: 10px;
}

#pageSelection {
  float: none;
  padding-top: 0.75rem;
}

/* Styling for radio field selection option sub-text */
.form-radio-options-sub-text {
  padding-top: 8px;
  font-weight: normal;
  font-size: 14px;
}

.flex-direction-column {
  flex-direction: column !important;
}
.vot-card {
  background-color: var(--background-color-primary-light-dark);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
h2 {
  font-weight: 500;
}
//tabs
.filler {
  width: 10px;
  position: relative;
  z-index: 1;
}
.move-left {
  position: relative;
  left: -10px;
}
.move-right {
  position: relative;
  right: -10px;
}
.vot-nav-option {
  display: inline-block;
  cursor: pointer;
  background-color: --var(--background-color-primary);
  color: var(--primary-theme);
  padding: 10px 20px;
  border: 1px solid var(--primary-theme);
  border-radius: 12px;
  width: 200px;
  height: 48px;
}
.vot_tab_text{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: "IBM Plex Sans";
  line-height: 14px;
}

.active {
  background-color: var(--primary-theme);
  color: $white;
}
h5 {
  color: var(--text-primary-color);
}
#vot-nav {
  display: flex;
  justify-content: center;
}

.vot-table-video-player-container {
  display: flex;
}
.video-player-skeleton {
  min-width: 450px;
  min-height: 370px;
  border: 1px solid $line;
  background-color: var(--input-disabled-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $label;
  border-radius: 8px;
}
#videoPlaceholder {
  border: 1px solid $line;
  border-radius: 8px;
}
.label-div p {
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
}

#color {
  color: var(--primary-theme);
}
.loading-place-holder {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary-color)
}
.vcard .v-card__title {
  font-family: "IBM Plex Sans" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 0.005em !important;
  padding-left: 10px !important;
}
.vcard {
  background: $tabletitlecardBG !important;
}
@media screen and (max-width: 770px) {
  .vot-table-video-player-container {
    display: flex;
    flex-direction: column;
  }
  .half-page {
    width: 100%;
    // margin-top: 20px;
    // padding: 10px;
  }
  .vot-nav-option {
    min-width: unset;
  }
}
</style>
