<template>
  <div>
    <video
      ref = "videoPlayer"
      controls autoplay= "off"
      id="videoPlaceholder"
      controlsList="nodownload"
      allowFullScreen
      frameborder="0"
      height="376"
      style="width: 95%; min-width: 450px">
      <source :src="this.current.video_url" type="video/mp4"/>
    </video>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VideoViewer',
  computed: {
    ...mapState('ReviewVot', [
      'current'
    ])
  },
  watch: {
    current: function (newVal, oldVal) {
      if (newVal !== null || newVal !== undefined || newVal !== '' || newVal !== oldVal) {
        this.$refs.videoPlayer.load()
      }
    }
  }
}
</script>
